var default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
var resize_width = window.innerWidth;
var break_point = 767;
$(function() {
	var default_height = $(document).innerHeight();//アクセスした時の高さを取得
  /*------------------------*/
  //リンクの範囲を広くする
  /*------------------------*/
  $(".widelink").on('click',function() {
    var url = $(this).find("a").attr("href");
    if ($(this).find("a").attr("target") == '_blank') {
      window.open(url, '_blank');
      return false;
    } else {
      window.location = url;
      return false;
    }
	});

  //サイドバー追従処理
  // barFix();
  $(window).on('scroll',function() {
    //サイドバー追従処理
    barFix();
  });
  /*------------------------*/
  //ページトップ
  /*------------------------*/
	var topBtn = $('.pageTop');
  topBtn.hide();
  var docH = $(document).innerHeight(),
  winH = $(window).height(),
  footeH = $('.footer').outerHeight(),
  topBtnPos = docH - winH - footeH - 90,
  flag = false;
	// スクロールが200に達したらボタン表示
	$(window).scroll(function () {
    if (!flag) { //フラグがtrueでなければ取得
      docH = $(document).height(),
      winH = $(window).height()
      footeH = $('.footer').outerHeight(),
      topBtnPos = docH - winH - footeH - 90;
      flag = true;
    }
		if ($(this).scrollTop() > 200 && !topBtn.hasClass("float") && !topBtn.hasClass("nonfloat")) {
      topBtn.fadeIn().addClass("float");
    } else if ($(this).scrollTop() < topBtnPos && topBtn.hasClass("nonfloat")) {
      topBtn.removeClass("nonfloat").addClass("float");
    } else if ($(this).scrollTop() > topBtnPos && topBtn.hasClass("float")) {
      topBtn.removeClass("float").addClass("nonfloat");
		} else if ($(this).scrollTop() < 200) {
      topBtn.fadeOut().removeClass("float nonfloat");
    }
	});
	// スクロールしてトップ
	$(".pageTop,.pageTop--article").on('click',function () {
		$('body,html').animate({
				scrollTop: 0
		}, 500);
		return false;
  });

  //スムーススクロール（ページ内リンク）
  $('a[href^="#"]').on("click", function () {
    var f = 600;
    e = $(this).attr("href"),
    g = $(e == "#" || e == "" ? "html" : e),
    h = $(".gNav").height(),
    d = g.offset().top - h;
    $("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });
  $('.js-scroll').on("click", function () {
    var f = 600;
    e = $(this).attr("href"),
    g = $(e == "#" || e == "" ? "html" : e),
    h = $(".gNav").height() + 100,
    d = g.offset().top - h;
    $("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });

  //tab
  $('.tabMenu .tabMenu__item').on('click',function() {
    var i = $(this).index();
    // クリック済みタブのデザインを設定したcssのクラスを一旦削除
    $(this).siblings().removeClass('active');
    // クリックされたタブにクリック済みデザインを適用する
    $(this).addClass('active');
    // コンテンツを一旦非表示にし、クリックされた順番のコンテンツのみを表示
    $(this).parent().next(".tabItem").children(".tabList").hide().removeClass("show").eq(i).fadeIn(300).addClass("show");
  });

  //accordion
  $(".js-accordion__btn").on('click',function() {
    $(this).toggleClass("open");
    $(this).next(".js-accordion__item").slideToggle();
  });

  //js-area-pulldown-item
  $(".js-area-pulldown").on('click',function() {
    $(this).toggleClass("open");
    $(this).next(".js-area-pulldown-item").slideToggle();
  });

  //pulldown
  $(".js-pulldown .js-pullDownList").on('click',function() {
    $(this).toggleClass("is-show").find(".js-pullDownItem").not(":first").slideToggle();
  });

  //js-accordion--footer
  $(".js-accordion--footer").on('click',function() {
    if (default_width <= break_point) { // スマホのみ
      $(this).toggleClass("open");
      $(this).next(".js-accordion--footer-item").slideToggle();
    }
  });

  //監修非表示
  $(".supervision .btn--close").on('click',function() {
    $(this).parents().find(".supervision").hide(0);
  });

  //追従バナー
  var fixB = $(".fixBnr");
  if (fixB.length) {
    $(window).on('scroll',function () {
      var doch = $(document).innerHeight(), //ページ全体の高さ
      winh = $(window).innerHeight(), //ウィンドウの高さ
      bottom = doch - winh, //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
      footh = $(".footer").innerHeight();
      if ($(this).scrollTop() > 100 && $(this).scrollTop() < bottom - footh) {
        fixB.addClass("show");
      } else {
        fixB.removeClass("show");
      }
    });
  }
  //追従バナー
  var fixBarticle = $(".fixBnr--article");
  if (fixBarticle.length) {
    $(window).on('scroll',function () {
      var doch = $(document).innerHeight(), //ページ全体の高さ
      winh = $(window).innerHeight(), //ウィンドウの高さ
      bottom = doch - winh, //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
      footh = $(".footer").innerHeight();
      if ($(this).scrollTop() > winh && $(this).scrollTop() < bottom - 3000) {
        fixBarticle.addClass("show");
      } else {
        fixBarticle.removeClass("show");
      }
    });
  }

  /*----------------------------------------------------*/
  /* [PC][SP]UseragentでJSを切り替え
  /*----------------------------------------------------*/
  var getDevice = (function() {
  var ua = navigator.userAgent;
    if (!(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
      /*-------------------------------------------------------------------------*/
      /* PCの時
      /*-------------------------------------------------------------------------*/
      $(".widelink").hover(
        function(e) {
            $(this).addClass("hover");
        },
        function(e) {
            $(this).removeClass("hover");
        }
      );
    }
  })();

  /*------------------------*/
  //バーガーメニュー表示・非表示
  /*------------------------*/
  var burgerBtn = $('.burger');
  var headerHeight = $('.header').innerHeight();
  var headerInnerHeight = $('.gNav').height();
  // スクロールがヘッダーの高さに達したらボタン表示
  $(window).scroll(function () {
    if (default_width <= break_point && $(this).scrollTop() > headerHeight) {
      $(".header").addClass("show");
    } else {
      $(".header").removeClass("show");
    }
    // if (default_width <= break_point && $(this).scrollTop() > headerHeight) {
    //     burgerBtn.addClass("show");
    // } else if (default_width <= break_point && $(this).scrollTop() < headerHeight) {
    //   if (!burgerBtn.hasClass("is-open")) {
    //     burgerBtn.removeClass("show");
    //   }
    // } else {
    //   if (!burgerBtn.hasClass("is-open")) {
    //     burgerBtn.removeClass("show");
    //   }
    // }
    if (default_width >= break_point && $(this).scrollTop() > headerInnerHeight) {
      $(".gNavList").addClass("fixedNav");
    } else if (default_width >= break_point && $(this).scrollTop() < headerInnerHeight) {
      if ($(".gNavList").hasClass("fixedNav")) {
        $(".gNavList").removeClass("fixedNav");
      }
    } else {
      if ($(".gNavList").hasClass("fixedNav")) {
        $(".gNavList").removeClass("fixedNav");
      }
    }
  });
	//バーガーボタンクリック
	burgerBtn.on('click',function() {
		default_width = window.innerWidth;//クリックされた時の数値をdefault_widthに設定
		if (default_width <= break_point) {
      burgerBtn.toggleClass("is-open");
      if ($(".gNav").hasClass("show")) {
        $(".gNav").removeClass("show").addClass("hide");
      } else if ($(".gNav").hasClass("hide")) {
        $(".gNav").removeClass("hide").addClass("show");
      } else {
        $(".gNav").addClass("show");
      }
			if ($(this).hasClass("is-open")) { //バーガーメニューが開かれた状態か
        $(".headerNav__cover").height(window.innerHeight).removeClass("hide").addClass("show");
			} else {
        $(".headerNav__cover").height(0).addClass("hide").removeClass("show");
			}
		}
	});
  //カバーをクリック
	$(".headerNav__cover,.gNav a").on('click',function() {
    if (default_width <= break_point) {
  		$(".gNav").removeClass("show").addClass("hide");
  		burgerBtn.removeClass("is-open");
      $(".headerNav__cover").addClass("hide").removeClass("show");
    }
	});
  
	/*------------------------*/
	//リサイズ時の処理（リサイズした瞬間に走る）
	/*------------------------*/
	$(window).on('resize',function() {
		if (default_width == window.innerWidth){//アクセスした時と、リサイズした時のウィンドウ幅が同じかどうかを判定
			return;//アクセスした時と、リサイズした時のウィンドウ幅が同じだった時に処理を抜ける
		} else {
			default_width = window.innerWidth;//リサイズ処理がされた場合、default_widthの数値を更新
			resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ時）
				/*------------------------*/
        //バーガーメニューを開いたままリサイズされた時の処理
        $(".gNav").removeClass("show hide");
				burgerBtn.removeClass("is-open");
				$(".headerNav__cover").height(0).addClass("hide").removeClass("show");
			} else {
				/*------------------------*/
				//PC処理（リサイズ時）
				/*------------------------*/
        $(".gNav").removeClass("show hide");
			}
		}
	});

	/*------------------------*/
	//リサイズ完了時点処理（完了するまで処理されない）
	/*------------------------*/
	var timer = false;
	$(window).on('resize',function() {
		if (timer !== false) {
				clearTimeout(timer);
		}
		timer = setTimeout(function() {
			resize_width = $(window).innerWidth();//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ完了時）
        /*------------------------*/
        // $header.removeClass(scrollClass);
        $(".js-area-pulldown-item").hide();
        $(".js-area-pulldown").removeClass("open");
			} else {
				/*------------------------*/
				//PC処理（リサイズ完了時）
        /*------------------------*/
        $(".js-area-pulldown-item").show();
			}
      //サイドバー追従処理
      barFix();

      docH = $(document).height(),
      winH = $(window).height()
      footeH = $('.footer').outerHeight(),
      topBtnPos = docH - winH - footeH;
		}, 200);
	});
});

/*------------------------*/
//関数
/*------------------------*/
let barFix = function() {
	//該当のセレクタなどを代入
	const mainArea = $(".layout--2col__cont"), //メインコンテンツ
	sideWrap = $(".sideNav"), //サイドバーの外枠
	sideArea = $(".sideNav__inner"), //サイドバー
	wd = $(window); //ウィンドウ自体

  //値の初期化
  sideArea.css({"position": "", "bottom": "", "top": ""});
  sideWrap.css({"height": "","position": ""});
  sideArea.css({"position": "", "bottom": "", "top": ""});

  //メインとサイドの高さを比べる 
	let mainH = mainArea.innerHeight(),
	sideH = sideWrap.innerHeight(),
  now_width = window.innerWidth,
  isSmartPhone = false;
  if(now_width <= 1023) {
    // 指定幅より狭いのでスマホとして判断する
    isSmartPhone = true;
  }

	if(sideH < mainH && isSmartPhone == false) { //メインの方が高ければ色々処理する
		//サイドバーの外枠をメインと同じ高さにしてrelaltiveに（#sideをポジションで上や下に固定するため）
		sideWrap.css({"height": mainH,"position": "relative"});
		//サイドバーがウィンドウよりいくらはみ出してるか
		var sideOver = wd.height()-sideArea.height();
		//固定を開始する位置 = サイドバーの座標＋はみ出す距離
		var starPoint = sideArea.offset().top + (-sideOver);
		//固定を解除する位置 = メインコンテンツの終点
		var breakPoint = sideArea.offset().top + mainH;

    if(wd.height() < sideArea.height()){ //サイドメニューが画面より大きい場合
      if(starPoint < wd.scrollTop() && wd.scrollTop() + wd.height() < breakPoint){ //固定範囲内
        sideArea.css({"position": "fixed", "bottom": "0"}); 
      }else if(wd.scrollTop() + wd.height() >= breakPoint){ //固定解除位置を超えた時
        sideArea.css({"position": "absolute", "bottom": "0"});
      } else { //その他、上に戻った時
        sideArea.css("position", "static");
      }
    } else { //サイドメニューが画面より小さい場合
    var sideBtm = wd.scrollTop() + sideArea.height(); //サイドメニューの終点
    if(mainArea.offset().top < wd.scrollTop() && sideBtm < breakPoint){ //固定範囲内
      sideArea.css({"position": "fixed", "top": "0"});
    }else if(sideBtm >= breakPoint){ //固定解除位置を超えた時
      //サイドバー固定場所（bottom指定すると不具合が出るのでtopからの固定位置を算出する）
      var fixedSide = mainH - sideH;
      sideArea.css({"position": "absolute", "top": fixedSide});
      } else {
        sideArea.css("position", "static");
      }
    }
	}
  if (isSmartPhone == true) {
		sideWrap.css({"height": "auto","position": "static"});
	}
}

/*トップページ　スライダー*/
let sliderPickup = function() {
	$(function() {
		//slider設置
    const $slider = $('.slider--pickup');
      $slider.slick({
        autoplay: true,
        autoplaySpeed: 5000,
        accessibility: false,
        dots: false,
        slidesToShow: 3,
        arrows: true,
        responsive: [
          {
            breakpoint: 600,//ブレイクポイントを指定
            settings: {
              draggable: true,
              centerMode: true,
              slidesToShow: 1,
              centerPadding: "12%"
            }
          }
        ]
      });
	});
}

/*商品　スライダー*/
let sliderProduct = function() {
	$(function() {
		//slider設置
    const $slider = $('.slider--product');
      $slider.slick({
        autoplay: true,
        autoplaySpeed: 5000,
        accessibility: false,
        dots: true,
        slidesToShow: 1,
        arrows: true
      });
	});
}